import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../fbase";
import { onAuthStateChanged } from "firebase/auth";
import Header from "../../components/_header";

const AdminPage = () => {
  const navigate = useNavigate();

  const [spaceList, setSpaceList] = useState<[]>([]);

  const fetchSpaceListByAdmin = async () => {
    const token = await auth.currentUser?.getIdToken();
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space/admin/" + token)
      .then((res) => {
        setSpaceList(res.data.result);

        // let newAdvertisements:Advertisement[] = [];
        // res.data.map((e: any, i: number) => {
        //     let newAdvertisement = {
        //         link: e.url,
        //         image: e.image,
        //         desc: e.title,
        //     }
        //     // console.log(newAdvertisement);
        //     newAdvertisements.push(newAdvertisement);
        // })
        // setAdvertisements(newAdvertisements);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchChangeSpaceStatus = async (spaceId: number, status: string) => {
    axios.put(process.env.REACT_APP_BACKEND_API_URL+"/api/change_space_status", {
      "spaceId": spaceId,
      "status": status
    })
      .then((res) => {
        fetchSpaceListByAdmin();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const createSpace = async () => {
    const token = await auth.currentUser?.getIdToken();
    axios.post(process.env.REACT_APP_BACKEND_API_URL + "/space/admin", {
      "token": token,
      "name": "",
      "description": "",
      "status": "CLOSE",
    })
    .then((res) => {
      console.log(res);
      navigate("/admin/space/"+res.data.id+"/edit")
      fetchSpaceListByAdmin();
    })
    .catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {  // 🔥 3
      if(user === null) {
        alert('로그인이 필요한 페이지입니다.');
        // navigate('/');
      }
      fetchSpaceListByAdmin();
    });
  }, []);

  return (
    <div className="flex flex-col w-full">
      <Header />

      <div className="w-full flex justify-center">
        <div className="w-full max-w-4xl p-4 flex flex-col">
          <div className="flex w-full items-center justify-center gap-4 p-16">
            <div className="w-32 h-32 bg-gray-400 rounded-full"></div>
            <div className="text-3xl w-64">
              <p>님</p>
              <p>환영합니다!</p>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <p className="text-3xl font-bold">공간 목록</p>
            <div className="grid grid-cols-3 gap-4">
              {
                spaceList.map((e:any, i:number) => {
                  return (
                    <div className="flex flex-col rounded-3xl shadow-md">
                      <Link to={'/admin/space/'+e.id}>
                        <img className="rounded-t-2xl" src="https://via.placeholder.com/1800x600"/>
                        <div className="p-4 flex flex-col gap-1 h-32">
                          <p className="text-2xl font-bold">
                            {e.name}
                          </p>
                          <div className="flex justify-between text-gray-600 text-lg">
                            위치 | 영업시간
                          </div>
                          <div >
                            {e.status}
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })
              }
              <div className="flex flex-col rounded-3xl shadow-md" onClick={createSpace}>
                <img className="rounded-t-2xl" src="https://via.placeholder.com/1800x600"/>
                <div className="p-4 flex h-32 items-center justify-center">
                  <p className="text-3xl font-bold">공간 추가</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div>
            {
              spaceList.map((e: any, i: number) => {
                return (
                  <div key={'space_'+i} className="flex gap-2">
                    {e.id}
                    <Link to={'space/'+e.id}>
                      <div className="w-32">
                        {e.name}
                      </div>
                    </Link>
                    <div className="flex gap-2">
                      <div>{e.status}</div>
                      <div className="flex gap-2 bg-gray-200">
                        {
                          ["OPEN", "CLOSE", "PAUSE"].map((e2:any, i2:number) => {
                            return (
                              <div onClick={() => fetchChangeSpaceStatus(e.id, e2)}>
                                {e2}
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                    <div>alarm</div>
                  </div>
                );
              })
            }
            <div onClick={createSpace}>
              공간 추가
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;