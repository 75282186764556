
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { auth } from "../../fbase";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import axios from "axios";

const ReservePage = () => {
  const params = useParams();
  const navigate = useNavigate();


  const fetchReloadQRCode = async () => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/reservation/code/"+params.Code)
      .then((res) => {
        console.log(res);
        axios.post(process.env.REACT_APP_BACKEND_API_URL+"/signal/"+res.data.space.id, 'message=1')
        .then((res) => {
          console.log(res);
        })
        .catch((e) => {
          console.log(e);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchConnectUser = (idToken: String) => {
    axios.post(process.env.REACT_APP_BACKEND_API_URL+"/api/connect", {
      "space_id": 0,
      "code": params.Code,
      "token": idToken
    })
    .then((res) => {
      console.log(res.data);
      if(res.data.result == true) {
        fetchReloadQRCode();
        navigate('./register');
      } else {
        console.log("ERROR!");
      }
    })
    .catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {  // 🔥 3
      console.log(user);
      if(user == null) {
        console.log(12);
        signInAnonymously(auth)
          .then(() => {
            // Signed in..
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
          });
      } else {
        user.getIdToken().then((e)=>{
          fetchConnectUser(e);
        })
      }
    });
  }, []);

  return (
    <div>
      hello world, Admin Space Page
    </div>
  );
};

export default ReservePage;