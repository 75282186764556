
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const RegisterPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState<any>({});
  const [uid, setUid] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  useEffect(() => {
    const userInfo = { ...location.state };
    setUserInfo(userInfo);
    setUid(userInfo.uid);
    setEmail(userInfo.email);
  }, []);

  const topDivRef = useRef<HTMLDivElement | null>(null);

  const fetchRegisterUser = async () => {
    axios.post(process.env.REACT_APP_BACKEND_API_URL+"/user", {
      "uid": uid,
      "name": name,
      "email": email,
      "phone": phone,
    }).then((res) => {
      console.log(res.data);
    }).catch((e) => {
      console.log(e);
    });
  }

  const handleSubmit = async (e: any) => {
    //validate
    await fetchRegisterUser();
    navigate('/');
    
    return;
  }

  return (
    <div ref={topDivRef} className="flex flex-col items-center w-full min-h-screen">
      <div className="flex flex-col items-center w-full max-w-4xl gap-6 py-8 flex-grow p-2">
        <form className="w-72 flex flex-col items-center" onSubmit={handleSubmit}>
          <label className="flex">
            <p className="w-32">이메일</p>
            <input type="text" placeholder="이메일" autoFocus value={email} disabled className="outline-none w-full"
            onChange={(e) => {
              setEmail(e.target.value);
            }}/>
          </label>
          <label className="flex">
            <p className="w-32">닉네임</p>
            <input type="text" placeholder="닉네임" autoFocus className="outline-none w-full" required
            onChange={(e) => {
              setName(e.target.value);
            }}/>
          </label>
          <label className="flex">
            <p className="w-32">전화번호</p>
            <input type="text" placeholder="전화번호" autoFocus className="outline-none w-full" required
            onChange={(e) => {
              setPhone(e.target.value);
            }}/>
          </label>
          <input type="submit" className="bg-gray-300 rounded-lg p-1 text-white font-bold mt-4" value={"회원가입"}/>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;