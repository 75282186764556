import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/_header";

const AdminSpacePage = () => {

  const params = useParams();

  const [space, setSpace] = useState<any>(null);
  const [waitingList, setWaitingList] = useState<any>([]);
  const [reserveList, setReserveList] = useState<any>([]);

  const fetchSpaceById = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space/"+spaceId)
      .then((res) => {
        setSpace(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchCallNext = async () => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/api/call_next/"+params.spaceId)
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchWaitingList = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/api/waiting_list/"+spaceId)
      .then((res) => {
        console.log(res);
        setWaitingList(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchReserveList = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/reservation/space/"+spaceId)
      .then((res) => {
        console.log(res);
        setReserveList(res.data.result.reverse());
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // const fetchChangeSpaceStatus = async (spaceId: number, status: string) => {
  //   axios.put("http://localhost:8080/api/change_space_status", {
  //     "spaceId": spaceId,
  //     "status": status
  //   })
  //     .then((res) => {
  //       fetchSpaceListByUserId(adminId);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  useEffect(() => {
    fetchSpaceById(Number(params.spaceId));
    fetchWaitingList(Number(params.spaceId));
    fetchReserveList(Number(params.spaceId));
    // fetchSpaceListByUserId(adminId);
  }, []);

  return (
    <div className="flex flex-col w-full">
      <Header />
      <div className="w-full flex flex-col items-center justify-center">
        <img src="https://via.placeholder.com/1800x600" className="max-w-4xl"></img>
        <div className="w-full max-w-4xl p-4 flex flex-col gap-4">
          <div className="py-4 flex flex-col gap-2 justify-center">
            <p className="text-4xl font-bold">{space?.name}</p>
            <div className="flex justify-between">
              <p className="text-gray-600 text-xl">
                위치 | 영업시간
              </p>
              <Link to='edit'>
                <p className="text-blue-400 border-blue-400 font-bold text-lg border-2 rounded-xl px-4">
                  정보 수정하기
                </p>
              </Link>
            </div>
          </div>
          <div className="flex items-center gap-6 border-2 rounded-xl p-4">
            <p className="text-2xl font-bold">영업 상태</p>
            <div className="flex gap-2">
              {
                ["OPEN", "CLOSE", "PAUSE"].map((e2:any, i2:number) => {
                  return (
                    // <div onClick={() => fetchChangeSpaceStatus(e.id, e2)}>
                    <div className="bg-gray-200 text-xl px-2 rounded-xl">
                      {e2}
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div className="flex items-center gap-6 border-2 rounded-xl p-4">
            <Link to='qrcode'>
              <p className="text-2xl font-bold">QR 페이지</p>
            </Link>
          </div>
          <div className="flex flex-col justify-center gap-6 border-2 rounded-xl p-4">
            <div className="flex justify-between">
              <p className="text-2xl font-bold">대기 목록</p>
              <p className="text-2xl font-bold">알림 설정</p>
            </div>
            <div className="flex items-center justify-center bg-blue-400 p-2 rounded-xl">
              <p className="text-white text-xl font-bold" onClick={fetchCallNext}>다음 번호({waitingList[0]?.number}) 호출</p>
            </div>

            <div className="flex flex-col p-4 text-lg gap-2">
              <div className="grid grid-cols-7 gap-2">
                <p className="border-r-2 text-center">번호</p>
                <p className="border-r-2 text-center">인원</p>
                <p className="border-r-2 text-center col-span-2">연락처</p>
                <p className="border-r-2 text-center col-span-2">예약시간</p>
                <p className="text-center">호출</p>
              </div>
              {
                waitingList.map((e: any) => (
                  <div className="grid grid-cols-7 gap-2">
                    <p className="text-center">{e.number}</p>
                    <p className="text-center"></p>
                    <p className="text-center col-span-2">{e.phone}</p>
                    <p className="text-center col-span-2"></p>
                    <div className="flex items-center justify-center">
                      <p className="bg-blue-400 px-4 rounded-xl text-white">호출</p>
                    </div>
                  </div>
                ))
              }
            </div>
            <p className="text-xl font-bold text-center">예약 내역 ▾</p>

            <div className="flex flex-col pb-4 px-4 text-lg gap-2">
              <div className="grid grid-cols-7 gap-2">
                <p className="border-r-2 text-center">번호</p>
                <p className="border-r-2 text-center">인원</p>
                <p className="border-r-2 text-center col-span-2">연락처</p>
                <p className="border-r-2 text-center col-span-2">예약시간</p>
                <p className="text-center">내역</p>
              </div>
              {
                reserveList.map((e: any) => (
                  <div className="grid grid-cols-7 gap-2">
                    <p className="text-center">{e.number}</p>
                    <p className="text-center"></p>
                    <p className="text-center col-span-2">{e.phone}</p>
                    <p className="text-center col-span-2"></p>
                    <p className="text-center">{e.status}</p>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="border-red-400 border-2 p-4 w-full text-2xl text-center rounded-xl text-red-400 font-bold">
            <p>공간 삭제</p>
          </div>

          <div className="text-center">@picktime</div>
        </div>
      </div>
    </div>
  );
};

export default AdminSpacePage;