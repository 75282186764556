import { Link } from "react-router-dom";
import Header from "../components/_header";

const HomePage = () => {
  return (
    <div className="flex flex-col w-full">
      <Header />
      {/* <img src="https://via.placeholder.com/1800x300"></img> */}
      <div className="w-full flex justify-center">
        <div className="w-full max-w-6xl p-4">
          <div className="py-56">
            <p className="text-3xl">한눈에 보이는 예약 정보</p>
            <p className="text-3xl">마음대로 커스터마이징 가능한 예약 시스템</p>
            <p className="text-9xl py-4">Pick-Time</p>
            <div className="py-8 flex gap-8">
              <div className="rounded-full border-blue-400 border-4 text-blue-400 p-4 px-12 text-4xl font-bold">
                예약하기
              </div>
              <Link to={"/admin"}>
                <div className="rounded-full bg-blue-400 text-white p-4 px-12 text-4xl font-bold">
                  장소등록
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;