import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LoginModal from "./LoginModal";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../fbase";
import axios from "axios";

type UserInfo = {
  name: string,
  email: string,
  phone: string,
};

const Header = () => {

  const location = useLocation();

  const [modalStatus, setModalStatus] = useState<boolean>(false);
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [showShortCut, setShowShortCut] = useState<boolean>(false);

  const fetchUserInfo = (idToken: string) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/user/token/"+idToken)
        .then((res) => {
          setUserInfo({
            name: res.data.name,
            email: res.data.email,
            phone: res.data.phone,
          });
        })
        .catch((e) => {
            console.log(e);
        });
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {  // 🔥 3
      if(user !== null) {
        user.getIdToken().then((e)=>{
          fetchUserInfo(e);
        })
      }
      console.log(user);
    });
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
    window.location.reload();
  }

  return (
    <div>
      <div className="w-full flex justify-center shadow-lg">
        <div className="relative flex max-w-6xl w-full justify-center items-center px-4 py-2 ">
          <p className="font-bold text-2xl">Pick-Time</p>
          <div className="absolute left-0 px-4">
            <p className="text-lg font-bold" onClick={() => setShowShortCut(!showShortCut)}>
              바로가기
            </p>
          </div>
          <div className="absolute right-0 px-4">
            {
              userInfo === null ? (
                <div>
                  <div onClick={() => setModalStatus(true)} className="text-lg font-bold">
                    로그인
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-4 text-lg font-bold">
                  <div>알림</div>
                  <div onClick={handleLogout}>
                    로그아웃
                  </div>
                </div>
              )
            }
          </div>
          <LoginModal status={modalStatus} setStatus={setModalStatus} />
          <div className={"absolute left-0 top-full my-2 z-50 bg-white border-b-2 rounded-xl "+(showShortCut?"":"hidden")}>
            <p className="p-4 font-bold text-lg">바로가기</p>
            <div className="border-[1px] w-full"/>
            <div className="p-4">
              <p className="font-bold text-lg">관리자</p>
              <Link to='/admin'>
                <div>
                관리자페이지
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Header;