import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../../fbase";

const ReserveRegisterPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [reservation, setReservation] = useState<any>(null);
  const [phone, setPhone] = useState("");

  const fetchReservationByCode = async (Code: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/reservation/code/"+Code)
      .then((res) => {
        setReservation(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchReservationByCode(Number(params.Code));
  }, []);


  const fetchUpdateReservationInfo = async () => {
    const token = await auth.currentUser?.getIdToken();
    axios.post(process.env.REACT_APP_BACKEND_API_URL + "/api/user_reserve",{
      "space_id": reservation.space.id,
      "code": params.Code,
      "phone": phone,
      "token": token
    })
    .then((res) => {
      if(res.data.result == true) {
        navigate('../result');
      } else {
        console.log("ERROR!");
      }
    })
    .catch((e) => {
      console.log(e);
    });
  }

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <p className="w-full text-center p-2 font-bold text-xl shadow-lg">
        {reservation!=null?reservation.space.name:""}
      </p>
      {/* <div relative>
        
      </div> */}
      <div className="h-full w-full bg-cover bg-[url(https://media.triumphmotorcycles.co.uk/image/upload/f_auto/q_auto/sitecoremedialibrary/media-library/images/central%20marketing%20team/for%20the%20ride/experiences/fve%20update/cafe/fve-cafe-hero-1920x1080.jpg)]">
        <div className="bg-[#ffffff88] backdrop-blur-sm h-full w-full flex items-center justify-center">
          <p className="text-6xl font-bold text-black">{reservation!=null?reservation.number:""}</p>
        </div>
      </div>
      <div className="w-full h-full flex flex-col items-center px-8 py-4 justify-between">
        <div className="flex flex-col gap-6 w-full">
          <div className="flex flex-col gap-2">
            <p className="font-bold">연락처</p>
            <input type="text" placeholder="000-0000-0000"
              className="border-2 p-2"
              onChange={(e)=>setPhone(e.target.value)} />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">인원</p>
            <input type="text" placeholder="인원" className="border-2 p-2"/>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold">약관동의</p>
            <div className="flex flex-col gap-2 m-2">
              <div className="flex gap-2">
                <input type="checkbox" />
                <label className="font-bold" >전체 동의</label>
              </div>
              <span className="border-[1px] rounded-full"/>
              <div className="flex gap-2">
                <input type="checkbox" />
                <label>만 14세 이상입니다 <span className="text-blue-400">(필수)</span></label>
              </div>
              <div className="flex gap-2">
                <input type="checkbox" />
                <label>이용 약관 <span className="text-blue-400">(필수)</span></label>
              </div>
              <div className="flex gap-2">
                <input type="checkbox" />
                <label>마케팅 동의 <span className="text-gray-400">(선택)</span></label>
              </div>
            </div>
          </div>
          <div className="bg-blue-400 p-2 text-center rounded-xl text-white font-bold"
            onClick={fetchUpdateReservationInfo}>
            <p>예약하기</p>
          </div>
        </div>
        <div className="pt-4">@picktime</div>
      </div>
    </div>
  );
};

export default ReserveRegisterPage;