import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

const AdminSpaceQRCodePage = () => {
  const params = useParams();

  const [space, setSpace] = useState<any>(null);
  const [code, setCode] = useState<string>("");
  const [descShow, setDescShow]= useState<boolean>(false);

  const [reload, setReload] = useState<any>(null);

  const fetchReloadQRCode = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/signal/"+spaceId, {timeout: 10000})
    .then((res) => {
      console.log(res);
      fetchQRCode(spaceId);
      fetchReloadQRCode(spaceId);
    })
    .catch((e) => {
      console.log(e);
      fetchReloadQRCode(spaceId);
    });
  }

  const fetchSpaceById = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space/"+spaceId)
      .then((res) => {
        setSpace(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchQRCode = async (spaceId: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/api/get_qrcode/"+spaceId)
      .then((res) => {
        setCode(res.data.result);
        console.log(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const testAsync = async () => {
    axios.post(process.env.REACT_APP_BACKEND_API_URL+"/signal/"+Number(params.spaceId), 'message=1');
  }

  useEffect(() => {
    fetchSpaceById(Number(params.spaceId));
    fetchQRCode(Number(params.spaceId));
    setReload(new Date());
    fetchReloadQRCode(Number(params.spaceId));
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="bg-gray-600 text-center p-2 text-2xl font-bold">
        picktime
      </div>
      <div className="flex h-full bg-red-50 relative">
        <img src="https://media.triumphmotorcycles.co.uk/image/upload/f_auto/q_auto/sitecoremedialibrary/media-library/images/central%20marketing%20team/for%20the%20ride/experiences/fve%20update/cafe/fve-cafe-hero-1920x1080.jpg"/>
        <div className="absolute top-0 h-full w-full flex flex-col">
          <div className="flex h-full">
            <div className="p-16 py-20 gap-12 flex flex-col items-center justify-start bg-[#ffffffbb] backdrop-blur-sm">
              <p className="text-5xl font-bold">대기 접수</p>
              <Link to={window.location.origin+"/reserve/"+code}>
                <QRCodeSVG
                      value={window.location.origin+"/reserve/"+code}
                      className="w-72 h-fit p-4 bg-white rounded-3xl" bgColor="#00000000" level="H"/>
              </Link>
            </div>
            <div className="flex flex-col w-full p-8 items-end">
              <p className="text-white text-5xl font-bold">{space?.name}</p>
            </div>
          </div>
          <div className="flex bg-[#ffffffdd] backdrop-blur-sm p-4 px-8 gap-8">
            <div className="flex flex-col">
              <p className="text-2xl font-bold">현재 대기 4팀</p>
              <p className="text-xl ">예상 대기 시간 약 12분</p>
            </div>
            <div className="h-full border-[1px] border-gray-600 rounded-full"/>
            <div className="flex items-center gap-4">
              <p className="text-xl font-bold rounded-md bg-blue-400 text-white px-4 py-2">123</p>
              <p className="text-xl font-bold rounded-md bg-gray-400 px-4 py-2">123</p>
              <p className="text-xl font-bold rounded-md bg-gray-400 px-4 py-2">123</p>
              <p className="text-xl font-bold rounded-md bg-gray-400 px-4 py-2">123</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex w-full h-full flex-col">
        <div className="bg-gray-600 text-center p-2 text-xl">
          picktime
        </div>
        <div className="flex w-full h-full">
          <div className="w-2/3 h-full border-r-2 flex flex-col">
            <div className="h-full flex flex-col items-center justify-center gap-12 p-16">
              <div className="flex items-center justify-center">
                <p className="text-5xl font-bold">
                  {space?.name}
                </p>
              </div>
              <QRCodeSVG
                value={window.location.origin+"/reserve/"+code}
                className="w-72 h-fit" />
            </div>
            <div className="w-full h-32 flex items-center justify-center bg-gray-300">
              광고
            </div>
          </div>
          <div className="w-1/2">
            <div className="h-1/2 flex flex-col border-b-2">
              <div className="text-3xl font-bold p-4 border-b-2">
                입장가능
              </div>
              <div>
                1
              </div>
            </div>
            <div className="h-1/2 flex flex-col">
              <div className="text-3xl font-bold p-4 border-b-2">
                준비중
              </div>
              <div>
                1
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      {/* <div className="h-screen w-full bg-white flex flex-col p-16">
        <div className="flex items-center justify-center py-2">
          <p className="text-4xl font-bold">
            {space?.name}
          </p>
        </div>
        <div className="h-full flex flex-col items-center justify-center">
          <div className="p-16">
            <QRCodeSVG
              value={window.location.origin+"/reserve/"+code}
              className="w-48 h-fit" />
          </div>
        </div>
        {/* <div className="flex justify-center">
          <p className="text-3xl font-bold py-3 px-12 bg-blue-400 text-white rounded-full"
            onClick={() => setDescShow(true)}>
            정보 더 보기
          </p>
        </div>
      </div>
      <div className={"absolute h-screen w-screen "+(descShow?"":"hidden")}>
        <div className="bg-black opacity-50 w-full h-full" onClick={()=>setDescShow(false)}/>
        <div className="absolute w-full p-4 h-1/3 bottom-0 bg-white">
          {space?.description}
        </div>

      </div> */}
    </div>
  );
};

export default AdminSpaceQRCodePage;