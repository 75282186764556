import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../../../components/_header";

const AdminSpaceEditPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [selectedImage, setSelectedImage] = useState<File|null>(null);

  const nameInputRef = useRef<HTMLInputElement>(null);
  const descInputRef = useRef<HTMLInputElement>(null);

  const fetchSpaceInfo = async () => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/space/"+params.spaceId)
      .then((res) => {
        setName(res.data.result.name)
        setDesc(res.data.result.description)
        if(nameInputRef.current != null) nameInputRef.current.value = res.data.result.name;
        if(descInputRef.current != null) descInputRef.current.value = res.data.result.description;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const fetchUpdateSpaceInfo = async () => {

    axios.put(process.env.REACT_APP_BACKEND_API_URL+"/space/edit/"+params.spaceId, {
      "token": "string",
      "name": name,
      "description": desc
    })
      .then((res) => {
        console.log(res);
        navigate(-1);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchSpaceInfo();
  }, []);

  const handleFileChanged = (e: any) => {
    setSelectedImage(e.target.files[0]);
  }

  return (
    <div className="flex flex-col w-full">
      <Header />
      <div className="w-full flex flex-col items-center justify-center">
        <img src="https://via.placeholder.com/1800x600" className="max-w-4xl"></img>
        <div className="max-w-4xl w-full p-2 flex gap-2">
          <div className="text-xl font-bold">배경 설정 : </div>
          <input type = "file" accept = "image/*"
            onChange={(e)=>{handleFileChanged(e)}}/>
        </div>
        <div className="w-full max-w-4xl p-4 flex flex-col gap-4">
          <div className="py-4 flex flex-col gap-2 justify-center gap-2">
            <p className="text-3xl font-bold">이름</p>
            <input ref={nameInputRef} type="text" placeholder="이름"
              onChange={(e)=>{setName(e.target.value)}}
              className="text-4xl font-bold border-2 p-2"/>
          </div>
          <div className="flex gap-4">
            <div className="flex flex-col w-full text-xl gap-2">
              <p>위치</p>
              <input type="text" placeholder="위치"
                className="border-2 p-2"/>
            </div>
            <div className="flex flex-col w-full text-xl gap-2">
              <p>영업시간</p>
              <input type="text" placeholder="영업시간"
                className="border-2 p-2"/>
            </div>
          </div>
          <div className="py-4 flex flex-col gap-2 justify-center gap-2">
            <p className="text-3xl font-bold">설명</p>
            <input ref={descInputRef} type="text" placeholder="설명"
              onChange={(e)=>{setDesc(e.target.value)}}
              className="text-4xl font-bold border-2 p-2"/>
          </div>
        </div>
        <div className="text-3xl font-bold border-2 rounded-md px-6 my-12 py-1"
          onClick={fetchUpdateSpaceInfo}>
          등록
        </div>
      </div>
      {/* <div className="flex flex-col w-full h-screen items-center justify-center gap-8 py-12">
        <input ref={nameInputRef} type="text" placeholder="Name"
          onChange={(e)=>{setName(e.target.value)}}
          className="text-3xl font-bold text-center"/>
        <QRCodeSVG
          value={"tmp page qrcode"}
          className="w-64 h-fit"></QRCodeSVG>
        <input ref={descInputRef} type="text" placeholder="Description"
          onChange={(e)=>{setDesc(e.target.value)}}
          className="text-3xl font-bold text-center"/>
        <div className="text-xl font-bold">배경 설정</div>
        <input type = "file" accept = "image/*"
          onChange={(e)=>{handleFileChanged(e)}}/>
        <div className="text-3xl font-bold border-2 rounded-md px-6 my-12 py-1"
          onClick={fetchUpdateSpaceInfo}>
          등록
        </div>
      </div> */}
    </div>
    
  );
};

export default AdminSpaceEditPage;