import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ReserveResultPage = () => {
  const params = useParams();

  const [reservation, setReservation] = useState<any>(null);
  const [phone, setPhone] = useState("");

  const fetchReservationByCode = async (Code: number) => {
    axios.get(process.env.REACT_APP_BACKEND_API_URL+"/reservation/code/"+Code)
      .then((res) => {
        setReservation(res.data);
        console.log(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    fetchReservationByCode(Number(params.Code));
  }, []);

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <p className="w-full text-center p-2 font-bold text-xl shadow-lg">
        {reservation!=null?reservation.space.name:""}
      </p>
      <div className="h-full w-full bg-cover bg-[url(https://media.triumphmotorcycles.co.uk/image/upload/f_auto/q_auto/sitecoremedialibrary/media-library/images/central%20marketing%20team/for%20the%20ride/experiences/fve%20update/cafe/fve-cafe-hero-1920x1080.jpg)]">
        <div className="bg-[#ffffff88] backdrop-blur-sm h-full w-full flex items-center justify-center">
        <p className="text-8xl font-bold py-6">{reservation!=null?reservation.number:""}</p>
        </div>
      </div>
      <div className="w-full flex flex-col items-center px-8 py-4 gap-6">
        <div className="w-full h-full">
          <p className="text-2xl font-bold pb-2">현재 대기 4팀</p>
          <p>예상 대기 시간 약 12분</p>
        </div>
        <div className="w-full flex flex-col gap-2">
          <div className="border-blue-400 border-2 p-2 w-full text-lg text-center rounded-xl text-blue-400 font-bold">
            <p>메뉴 보기</p>
          </div>
          <div className="bg-gray-400 p-2 w-full text-lg text-center rounded-xl text-white font-bold">
            <p>자리 보기</p>
          </div>
          <div className="flex gap-2">
            <div className="border-blue-400 border-2 p-2 w-full text-lg text-center rounded-xl text-blue-400 font-bold">
              <p>문의하기</p>
            </div>
            <div className="border-red-400 border-2 p-2 w-full text-lg text-center rounded-xl text-red-400 font-bold">
              <p>예약 취소</p>
            </div>
          </div>
        </div>
        <div>@picktime</div>
      </div>
    </div>
  );
};

export default ReserveResultPage;